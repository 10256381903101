import {Component} from '@angular/core';
import {ToastModule} from "primeng/toast";

@Component({
  selector: 'calimatic-toaster',
  standalone: true,
  imports: [
    ToastModule
  ],
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss'
})
export class ToasterComponent {
}
