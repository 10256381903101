import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {ToasterComponent} from "./shared/components/toaster/toaster.component";
import {ConfirmDeleteComponent} from "./shared/components/confirm-delete/confirm-delete.component";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToasterComponent, ConfirmDeleteComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
}
