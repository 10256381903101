import {HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {inject} from '@angular/core';
import {catchError, of} from 'rxjs';
import {AppUtils} from '../../shared/utils/app-utilities';
import {AuthService} from "../services/auth/auth.service";

export const AuthInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn) => {
  const authService = inject(AuthService);
  return processRequestWithToken(req, next, authService.getAccessToken())
    .pipe(
      catchError(error => {
        // Handle auth related errors here (e.g., 401 Unauthorized)
        if (error.status === 401) {
          authService.logout();
          return of(error);
        }

        // Handle other errors here
        console.error('An error occurred:', error);
        return of(error);
      }));
};

function processRequestWithToken(req: HttpRequest<any>, next: HttpHandlerFn, token: string) {
  if (AppUtils.isRequestForLmsAPI(req.url)) {
    const timeOffset = new Date().getTimezoneOffset();
    if (!!token) {
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
                TimeOffset: timeOffset.toString()
            }
        });
    } else {
        req = req.clone({
            setHeaders: {
                TimeOffset: timeOffset.toString() 
            }
        });
    }
}
return next(req);
}
