import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter, withComponentInputBinding, withHashLocation} from '@angular/router';
import {routes} from './app.routes';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {provideStore} from '@ngrx/store';
import {AuthInterceptor} from "./core/interceptors/auth.interceptor.service";
import {PrimeNgToasterService} from "./shared/services/toaster/primeNg-toaster-service";
import {ToasterModule} from "./shared/modules/Toaster-module";
import {AppTokens} from "./shared/utils/global";
import { DatePipe } from '@angular/common';
import { PrimeNgConfirmDialogService } from './shared/services/confirm-dialog/confirm-dialog.service';
import { ConfirmationModule } from './shared/modules/confirmation-module';

export const appConfig: ApplicationConfig = {
  providers: [
    DatePipe ,
    {provide: AppTokens.confirmation,useClass:PrimeNgConfirmDialogService},
    {provide: AppTokens.toaster, useClass: PrimeNgToasterService},
    provideRouter(routes, withHashLocation(),withComponentInputBinding()),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    provideAnimations(),
    importProvidersFrom([
      BrowserAnimationsModule,
      ToasterModule,
      ConfirmationModule,
    ]),
    provideStore()
  ]
};
