import {NgModule} from "@angular/core";
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";

@NgModule({
  imports: [ToastModule],
  providers: [MessageService]
})
export class ToasterModule {
}
